export default {
  // Login & Logout
  login: '/auth/login/operator',
  login_by_sso: '/auth/login/sso/operator',
  logout: '/auth/logout',
  verify_token: `/auth/verify_token`,

  // Queues
  queues: '/queues',
  pick_ticket: '/queues/pick_ticket',

  // Users
  users: '/users',

  // User's Skillset
  skillsets: '/skillsets',

  // Windows
  windows: '/windows',

  // Service Types
  service_types: '/service_types',

  // Tickets
  tickets: '/tickets',
  assign_ticket: '/tickets/assign',
  update_ticket_user: '/tickets/update_ticket_user',
  update_ticket_status: '/tickets/status',

  // Bookings
  bookings: '/bookings'
}
