import api from '@/apis'

export default {
  state: {
    visibility: false
  },
  mutations: {
    SET_WALK_IN_DIALOG_VISIBILITY(state, payload) {
      state.visibility = payload
    }
  },
  actions: {
    async createWalkInTicket({ commit, rootState, dispatch }, payload) {
      try {
        // Create walk-in talk
        const create_ticket_result = await api.createTicket(payload)
        if (create_ticket_result.data.success) {
          commit('SET_WALK_IN_DIALOG_VISIBILITY', false)
          let created_ticket_info = create_ticket_result.data.data

          // Get Service Type Info from store
          const service_type_info = rootState.general.service_types.find(service_type => service_type.service_type_id === created_ticket_info.service_type_id)

          // Add queue info to the ticket info for later use
          created_ticket_info.queue_name = created_ticket_info.prefix
          created_ticket_info.service_type_name = service_type_info.service_type
          created_ticket_info.threshold = service_type_info.threshold

          // Pick the ticket and Show the ticket management panel
          commit('SET_TICKET_MANAGEMENT_DIALOG_TICKET', created_ticket_info)
          dispatch('pickTicketFromQueue', {
            pick: {
              params: {
                user_id: payload.params.create_by_user_id,
                window_id: payload.params.window_id,
                queue_id: created_ticket_info.queue_name,
                ticket_user_id: created_ticket_info.ticket_user_id
              }
            },
            update: {
              params: {
                ticket_user_id: created_ticket_info.ticket_user_id,
                status: 'serving'
              }
            }
          })
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Error occur when the system trying to create walk-in ticket, please try again later.', icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Occur Error', icon: 'close', timeout: '2000' })
      }
    }
  }
}
