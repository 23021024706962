import api from '@/apis'

export default {
  state: {
    users: [],
    windows: [],
    selected_counter: null,
    service_types: [],
    user_skill_set: []
  },
  mutations: {
    SET_USERS_LIST(state, payload) {
      state.users = payload
    },
    SET_WINDOWS_LIST(state, payload) {
      state.windows = payload
    },
    SET_SERVICE_TYPES(state, payload) {
      state.service_types = payload
    },
    SET_SELECTED_COUNTER(state, payload) {
      state.selected_counter = payload
    },
    SET_USER_SKILL_SET(state, payload) {
      state.user_skill_set = payload
    }
  },
  actions: {
    initTicketingSystem({ dispatch, rootState }) {
      dispatch('fetchUserList')
      dispatch('fetchWindowList')
      dispatch('fetchServiceTypeList')
      dispatch('socket_update_queue')
      dispatch('fetchUserSkillSetList', rootState.auth.user_info.user_id)
    },
    async fetchUserList({ commit }) {
      try {
        const response = await api.getUsers()
        if (response.data.success) {
          commit('SET_USERS_LIST', response.data.data)
        } else {
          console.log('Users API occur error')
        }
      } catch (error) {
        console.log('API Server occur error: ', error.message)
      }
    },
    async fetchWindowList({ commit }) {
      try {
        const response = await api.getWindows()
        if (response.data.success) {
          commit('SET_WINDOWS_LIST', response.data.data)
        } else {
          console.log('Windows API occur error')
        }
      } catch (error) {
        console.log('API Server occur error: ', error.message)
      }
    },
    async fetchServiceTypeList({ commit }) {
      try {
        const response = await api.getServiceTypes()
        if (response.data.success) {
          commit('SET_SERVICE_TYPES', response.data.data)
        } else {
          console.log('Service Types API occur error')
        }
      } catch (error) {
        console.log('API Server occur error: ', error.message)
      }
    },
    async fetchUserSkillSetList({ commit }, payload) {
      try {
        const response = await api.getUserSkillset(payload)
        if (response.data.success) {
          commit('SET_USER_SKILL_SET', response.data.data)
        } else {
          console.log('Skill Set API occur error')
        }
      } catch (error) {
        console.log('API Server occur error: ', error.message)
      }
    }
  }
}
