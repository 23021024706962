export default {
  state: {
    visibility: true
  },
  mutations: {
    SET_NAVBAR_VISIBILITY(state, payload) {
      state.visibility = payload
    }
  }
}
