import api from '@/apis'

export default {
  state: {
    currentTicketVisibility: false,
    ticket: {},
    status: null,
    is_auto_pick_btn_disable: false
  },
  mutations: {
    SET_TICKET_PANEL_CARD_TICKET(state, payload) {
      state.currentTicketVisibility = true
      state.ticket = payload.ticket
      state.status = payload.status
    },
    RESET_TICKET_PANEL_CARD(state) {
      state.currentTicketVisibility = false
      state.ticket = {}
      state.status = null
      state.is_auto_pick_btn_disable = false
    },
    SET_TICKET_PANEL_CARD_IS_AUTO_PICK_BTN_DISABLE(state, payload) {
      state.is_auto_pick_btn_disable = payload
    }
  },
  actions: {
    // Check whether any ticket is processing by the current user in order to recover back to panel
    async checkHandlingTicket({ commit, rootState }, payload) {
      try {
        // Fetch the processing ticket list
        const queue_x = await api.getSpecificQueue('X')
        if (queue_x.data.success) {
          const processing_ticket_list = queue_x.data.data.queues
          if (processing_ticket_list.length) {
            // Find whether handled ticket is belong to current user
            let ticket = processing_ticket_list.find(ticket => ticket.handled_by_user_id === payload.user_id)
            if (ticket !== undefined) {
              // Get Ticket Service Type Details
              const service = rootState.general.service_types.find(service_type => service_type.service_type_id === ticket.service_type_id)

              // Get the Queue Info of the ticket
              if (service) {
                const queue_info = await api.getSpecificQueue(service.prefix)
                if (queue_info.data.success) {
                  const queue = queue_info.data.data
                  ticket.queue_name = service.prefix
                  ticket.service_type_name = queue.service_type_name
                  ticket.threshold = queue.threshold

                  // Get the ticket status
                  const response = await api.getTicketStatus(ticket.ticket_user_id)
                  if (response.data.success) {
                    const status = response.data.data.status

                    // Recover the ticket to panel
                    commit('SET_TICKET_PANEL_CARD_TICKET', {
                      ticket: ticket,
                      status: status
                    })
                  }
                }
              }
            }
          }
        }
      } catch (error) {
        console.log(error.message)
      }
    },
    async autoPickTicketFromQueue({ commit, rootState, dispatch }, payload) {
      try {
        commit('SET_TICKET_PANEL_CARD_IS_AUTO_PICK_BTN_DISABLE', true)
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Picking up the ticket...', loading: true })
        const response = await api.pickTicket(payload)
        if (response.data.success) {
          let ticket = response.data.data
          const service_type_info = rootState.general.service_types.find(service_type => service_type.service_type_id === ticket.service_type_id)
          ticket.service_type_name = service_type_info.service_type
          ticket.threshold = service_type_info.threshold
          ticket.queue_name = service_type_info.prefix

          // Show the picked ticket to the panel
          commit('SET_TICKET_PANEL_CARD_TICKET', {
            ticket: ticket,
            status: 'calling'
          })

          dispatch('announceTicket', {
            params: {
              ticket_user_id: ticket.ticket_user_id,
              status: 'calling'
            }
          })
          commit('SET_SNACKBAR', { status: true, color: 'success', text: 'Success', icon: 'check', timeout: '2000' })
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: 'No ticket is available for you right now.', icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Queue System occur error, please try again.', icon: 'close', timeout: '2000' })
      } finally {
        commit('SET_TICKET_PANEL_CARD_IS_AUTO_PICK_BTN_DISABLE', false)
      }
    },
    async completeTicket({ commit }, payload) {
      try {
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Completing the status of the ticket...', loading: true })
        const response = await api.updateTicketStatus(payload)
        if (response.data.success) {
          commit('RESET_TICKET_PANEL_CARD') // Reset the panel
          commit('SET_SNACKBAR', { status: true, color: 'success', text: 'Success', icon: 'check', timeout: '2000' })

          if (!payload.action) {
            const ticket = payload.params.ticket
            api.modifyTicketPosition({
              params: {
                ticket_id: ticket.ticket_id,
                ticket_user_id: ticket.ticket_user_id,
                user_id: payload.params.user_id,
                from_queue: 'X',
                to_queue: 'D'
              }
            })
          }
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: response.data.message, icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server occur error, please try again.', icon: 'close', timeout: '2000' })
      }
    },
    async announceTicket({ commit }, payload) {
      try {
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Calling Ticket...', loading: true })
        const response = await api.updateTicketStatus(payload)
        if (response.data.success) {
          commit('SET_SNACKBAR', { status: true, color: 'success', text: 'Success', icon: 'check', timeout: '2000' })
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Error occur, cannot call the ticket.', icon: 'close', timeout: '5000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Error', icon: 'close', timeout: '2000' })
      }
    }
  }
}
