<template>
  <v-app-bar hide-on-scroll app elevation="0" color="transparent" v-if="auth.status">
    <v-app-bar-nav-icon style="color: white" @click.stop="SET_NAVBAR_VISIBILITY(!navbar.visibility)"></v-app-bar-nav-icon>
    <v-toolbar-title style="color: white; font-weight: 500" class="d-none d-sm-none d-md-flex">{{ appbar_title }}</v-toolbar-title>
    <v-spacer></v-spacer>

    <!-- Search -->
    <v-toolbar-items class="pt-3" v-if="$route.name === 'Home'">
      <v-text-field @keyup.enter="searchTicket" v-model="keyword" single-line placeholder="Search" dark filled rounded dense></v-text-field>
    </v-toolbar-items>

    <!-- User info -->
    <v-menu open-on-hover bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="transparent" elevation="0" dark v-bind="attrs" v-on="on" class="text-capitalize">
          {{ auth.user_info.firstname }} {{ auth.user_info.lastname }}
          <v-icon right dark> mdi-menu-down </v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item>
          <v-card class="mx-auto" max-width="344" flat>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline mb-2">Information</div>
                <v-list-item-title class="headline">{{ auth.user_info.firstname }} {{ auth.user_info.lastname }}</v-list-item-title>
                <v-list-item-subtitle>Team: {{ auth.user_info.team_name }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-avatar tile size="100" color="transparent">
                <v-avatar color="grey" tile size="100">
                  <v-icon dark x-large> mdi-account-circle </v-icon>
                </v-avatar>
              </v-list-item-avatar>
            </v-list-item>

            <v-card-actions>
              <v-btn outlined rounded small block @click="logoutUser({ token: auth.token, sso: $auth })"> Logout </v-btn>
            </v-card-actions>
          </v-card>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  name: 'appbar',
  data() {
    return {
      appbar_title: process.env.VUE_APP_CENTER_NAME,
      keyword: null
    }
  },
  computed: {
    ...mapState(['auth', 'general', 'navbar', 'currentQueueCard', 'handledQueueCard'])
  },
  methods: {
    ...mapMutations(['SET_SNACKBAR', 'SET_NAVBAR_VISIBILITY', 'INIT_TICKET_MANAGEMENT_DIALOG', 'INIT_BACK_QUEUE_DIALOG']),
    ...mapActions(['logoutUser']),
    searchTicket() {
      // Check whether the user selected the counter

      if (this.general.selected_counter === null) this.SET_SNACKBAR({ status: true, color: 'error', text: 'Please select the counter on the upper right corner first', icon: 'close', timeout: '2000' })
      else {
        let is_found = false
        const queues = [...this.currentQueueCard.items, ...this.handledQueueCard.items]
        queues.forEach(queue => {
          queue.queues.every(ticket => {
            if (ticket.code === this.keyword) {
              is_found = true

              ticket.queue_name = queue.queue_name
              ticket.service_type_name = queue.service_type_name
              ticket.threshold = queue.threshold

              // Show the ticket management dialog
              if (ticket.queue_name !== 'U' && ticket.queue_name !== 'D') {
                this.INIT_TICKET_MANAGEMENT_DIALOG({ visibility: true, ticket: ticket })
              } else {
                // Show the back to queue dialog
                this.INIT_BACK_QUEUE_DIALOG({ visibility: true, ticket: ticket, current_queue_name: ticket.queue_name })
              }
              return false
            }
            return true
          })
        })

        if (!is_found) {
          this.SET_SNACKBAR({ status: true, color: 'error', text: 'No such ticket found', icon: 'close', timeout: '2000' })
        }
      }
    }
  },
  watch: {
    keyword() {
      if (this.keyword.charAt(0) !== this.keyword.charAt(0).toUpperCase()) {
        this.keyword = this.keyword.charAt(0).toUpperCase()
      }
    }
  }
}
</script>

<style scoped></style>
