import api from '@/apis'

export default {
  actions: {
    // Trigger(fetch latest queues status) when the queues is updated
    async socket_update_queue({ rootState, commit }) {
      try {
        if (rootState.auth.status) {
          const response = await api.getQueues()
          if (response.data.success) {
            let queues = response.data.data
            commit('SET_CURRENT_QUEUE_CARD_ITEMS', queues.current_queues) // Set current queue list
            commit('SET_HANDLED_QUEUE_CARD_ITEMS', queues.handled_queues) // Set handled queue list
          } else {
            commit('SET_SNACKBAR', { status: true, color: 'error', text: response.data.message, icon: 'close', timeout: '2000' })
          }
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Error, please try again later.', icon: 'close', timeout: '2000' })
      }
    }
  }
}
