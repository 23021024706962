export default {
  state: {
    headers: [
      { text: 'PPL No.', value: 'queue_length', width: '80', sortable: false },
      { text: 'Status', value: 'service', width: '80', sortable: false },
      { text: 'Service', value: 'service_name', width: '150', sortable: false },
      { text: 'Tickets', value: 'tickets', sortable: false }
    ],
    items: []
  },
  mutations: {
    SET_HANDLED_QUEUE_CARD_ITEMS(state, payload) {
      state.items = payload
    }
  }
}
