import api from '@/apis'

export default {
  state: {
    visibility: false,
    ticket: {},
    current_queue_name: null
  },
  mutations: {
    INIT_BACK_QUEUE_DIALOG(state, payload) {
      state.visibility = payload.visibility
      state.ticket = payload.ticket
      state.current_queue_name = payload.current_queue_name
    },
    RESET_BACK_QUEUE_DIALOG(state) {
      state.visibility = false
      state.ticket = {}
      state.current_queue_name = null
    }
  },
  actions: {
    async backTicketToQueue({ commit }, payload) {
      try {
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Loading...', loading: true })
        // Assign the ticket to queue by creating a new ticket-life-cycle using assignTicket API
        const response = await api.assignTicket(payload)
        if (response.data.success) {
          api.modifyTicketPosition(payload)
          commit('RESET_BACK_QUEUE_DIALOG')
          commit('SET_SNACKBAR', { status: true, color: 'success', text: 'Success', icon: 'check', timeout: '2000' })
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: response.data.message, icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server occur error, please try again.', icon: 'close', timeout: '2000' })
      }
    }
  }
}
