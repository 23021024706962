export default {
  state: {
    headers: [
      { text: 'PPL No.', value: 'queue_length', width: '80', sortable: false },
      { text: 'Prefix　　Create', value: 'prefix', width: '160', sortable: false },
      { text: 'Service', value: 'service', width: '210', sortable: false },
      { text: 'Tickets', value: 'tickets', sortable: false }
    ],
    items: []
  },
  mutations: {
    SET_CURRENT_QUEUE_CARD_ITEMS(state, payload) {
      state.items = payload
    }
  }
}
