import api from '@/apis'

export default {
  state: {
    visibility: false,
    page: 1,
    ticket: {},
    pickedTicket: {},

    // Remarks Area
    is_remark_submit_btn_show: false,
    is_remark_submit_btn_disable: false
  },
  mutations: {
    INIT_TICKET_MANAGEMENT_DIALOG(state, payload) {
      state.visibility = payload.visibility
      state.ticket = payload.ticket
    },
    RESET_TICKET_MANAGEMENT_DIALOG(state) {
      state.visibility = false
      state.ticket = {}
      state.page = 1
      state.pickedTicket = {}
      state.is_remark_submit_btn_show = false
      state.is_remark_submit_btn_disable = false
    },
    SET_TICKET_MANAGEMENT_DIALOG_TICKET(state, payload) {
      state.ticket = payload
    },
    SET_TICKET_MANAGEMENT_DIALOG_PAGE(state, payload) {
      state.page = payload
    },
    SET_TICKET_MANAGEMENT_DIALOG_PICKED_TICKET(state, payload) {
      state.pickedTicket = { ...state.ticket, ...payload }
    },
    SET_TICKET_MANAGEMENT_DIALOG_IS_REMARK_SUBMIT_BTN_SHOW(state, payload) {
      state.is_remark_submit_btn_show = payload
    },
    SET_TICKET_MANAGEMENT_DIALOG_IS_REMARK_SUBMIT_BTN_DISABLE(state, payload) {
      state.is_remark_submit_btn_disable = payload
    }
  },
  actions: {
    // Pick Ticket from queue and update the status of the ticket after successfully picked
    async pickTicketFromQueue({ commit, dispatch }, payload) {
      try {
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Picking up the ticket...', loading: true })
        const response = await api.pickTicket(payload.pick)
        if (response.data.success) {
          commit('SET_TICKET_MANAGEMENT_DIALOG_PICKED_TICKET', response.data.data)

          setTimeout(() => {
            dispatch('updateTicketStatus', payload.update)
          }, 100)

          // Move the ticket position if requested
          if (payload.is_move_ticket) {
            dispatch('moveTicketPosition', payload.move)
          }
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: 'You cannot pick this ticket', icon: 'close', timeout: '5000' })
          commit('RESET_TICKET_MANAGEMENT_DIALOG')
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Error', icon: 'close', timeout: '2000' })
        commit('RESET_TICKET_MANAGEMENT_DIALOG')
      }
    },
    async updateTicketStatus({ commit, rootState }, payload) {
      try {
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Updating the status of the ticket...', loading: true })
        const response = await api.updateTicketStatus(payload)
        if (response.data.success) {
          commit('SET_SNACKBAR', { status: true, color: 'success', text: 'Success', icon: 'check', timeout: '2000' })

          // Disable the ticket management dialog and pass the data of picked ticket to ticket panel
          if (payload.params.status === 'calling' || payload.params.status === 'serving') {
            commit('SET_TICKET_PANEL_CARD_TICKET', {
              ticket: rootState.ticketMngtDialog.pickedTicket,
              status: payload.params.status
            })
            commit('RESET_TICKET_MANAGEMENT_DIALOG')
          }
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Error occur, cannot update the status.', icon: 'close', timeout: '5000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Error', icon: 'close', timeout: '2000' })
      }
    },
    async moveTicketPosition({ commit }, payload) {
      try {
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Updating the queue...', loading: true })
        const response = await api.modifyTicketPosition(payload)
        if (response.data.success) {
          commit('SET_SNACKBAR', { status: true, color: 'success', text: 'Success', icon: 'check', timeout: '2000' })
          commit('RESET_TICKET_MANAGEMENT_DIALOG')
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Error occur, cannot update the position of the ticket.', icon: 'close', timeout: '5000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Error', icon: 'close', timeout: '2000' })
      }
    },
    async updateTicketRemarks({ commit }, { ticket_id, service_type_id, remarks }) {
      try {
        commit('SET_TICKET_MANAGEMENT_DIALOG_IS_REMARK_SUBMIT_BTN_DISABLE', true)
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Updating the remarks...', loading: true })
        const response = await api.updateTicket({ params: { ticket_id, service_type_id, remarks } })
        if (response.data.success) {
          commit('SET_SNACKBAR', { status: true, color: 'success', text: 'Success', icon: 'check', timeout: '2000' })
          commit('SET_TICKET_MANAGEMENT_DIALOG_IS_REMARK_SUBMIT_BTN_SHOW', false)
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Error', icon: 'close', timeout: '2000' })
      } finally {
        commit('SET_TICKET_MANAGEMENT_DIALOG_IS_REMARK_SUBMIT_BTN_DISABLE', false)
      }
    }
  }
}
