import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Auth from '@okta/okta-vue'
import { OktaAuth } from '@okta/okta-auth-js'
import { LoginCallback } from '@okta/okta-vue'

const oktaAuth = new OktaAuth({
  issuer: process.env.VUE_APP_OKTA_ISSUER,
  clientId: process.env.VUE_APP_OKTA_CLIENT_ID,
  redirectUri: process.env.VUE_APP_OKTA_REDIRECT_URI,
  scopes: ['openid', 'profile', 'email']
})

Vue.use(VueRouter)
Vue.use(Auth, { oktaAuth })

const routes = [
  {
    path: '/',
    name: 'Loading',
    component: () => import(/* webpackChunkName: "Loading" */ '../views/Loading.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/login/callback',
    component: LoginCallback
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
  },
  {
    path: '/announcement',
    name: 'Announcement',
    component: () => import(/* webpackChunkName: "Announcement" */ '../views/Announcement.vue')
  },
  {
    path: '/ticket/:ticket_id',
    name: 'Ticket',
    component: () => import(/* webpackChunkName: "Ticket" */ '../views/Ticket.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // avoid redirect to same page
  if (to === from) {
    return
  }

  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/', '/login', '/login/callback']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = store.state.auth.status
  if (authRequired && !loggedIn) {
    return next('/login')
  }

  // prevent logged in user access login page again
  if (to.path === '/' && loggedIn) {
    return next('/home')
  }

  next()
})

export default router
