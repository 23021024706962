import api from '@/apis'
import router from '@/router'

export default {
  state: {
    visibility: false,
    is_pre_selected: false,
    service_type_id: null,
    booking_records: [],
    is_booking_valid: false,
    lookup_error_msg: null
  },
  mutations: {
    SET_CREATE_TICKET_DIALOG_VISIBILITY(state, payload) {
      state.visibility = payload

      // Reset the dialog after closed
      if (!payload) {
        state.visibility = false
        state.is_pre_selected = false
        state.service_type_id = null
        state.booking_records = []
        state.is_booking_valid = false
        state.lookup_error_msg = null
      }
    },
    SET_CREATE_TICKET_DIALOG_IS_PRE_SELECTED(state, payload) {
      state.is_pre_selected = payload
    },
    SET_CREATE_TICKET_DIALOG_SERVICE_TYPE_ID(state, payload) {
      state.service_type_id = payload
    },
    SET_CREATE_TICKET_DIALOG_BOOKING_RECORD(state, payload) {
      state.booking_records = payload
    },
    SET_CREATE_TICKET_DIALOG_LOOKUP_ERROR_MESSAGE(state, payload) {
      state.lookup_error_msg = payload
    },
    SET_CREATE_TICKET_DIALOG_IS_BOOKING_VALID(state, payload) {
      state.is_booking_valid = payload
    }
  },
  actions: {
    initCreateTicketDialog({ rootState, commit }, service_type_id) {
      try {
        // Check whether the user currently handling any ticket
        if (Object.keys(rootState.ticketPanelCard.ticket).length) throw Error('Please finish your current ticket before trying to handle new ticket.')

        // Check whether the user selected the counter
        if (rootState.general.selected_counter === null) throw Error('Please select the counter on the upper right corner first')

        // Check whether selected service type
        if (service_type_id) {
          commit('SET_CREATE_TICKET_DIALOG_SERVICE_TYPE_ID', service_type_id)
          commit('SET_CREATE_TICKET_DIALOG_IS_PRE_SELECTED', true)
        }

        commit('SET_CREATE_TICKET_DIALOG_VISIBILITY', true)
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: error.message, icon: 'close', timeout: '2000' })
      }
    },
    async checkBooking({ commit }, payload) {
      try {
        const response = await api.getBookings(payload)
        if (response.data.success) {
          if (response.data.data.length) {
            commit('SET_CREATE_TICKET_DIALOG_BOOKING_RECORD', response.data.data)
            commit('SET_CREATE_TICKET_DIALOG_IS_BOOKING_VALID', true)
            commit('SET_CREATE_TICKET_DIALOG_LOOKUP_ERROR_MESSAGE', null)
          } else {
            commit('SET_CREATE_TICKET_DIALOG_BOOKING_RECORD', [])
            commit('SET_CREATE_TICKET_DIALOG_IS_BOOKING_VALID', false)
            commit('SET_CREATE_TICKET_DIALOG_LOOKUP_ERROR_MESSAGE', 'No such record found')
          }
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Error occur when the system trying to create ticket, please try again later.', icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Occur Error', icon: 'close', timeout: '2000' })
      }
    },
    async createNormalTicket({ state, commit }, payload) {
      try {
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Creating the ticket...', loading: true })
        const response = await api.createTicket(payload)
        if (response.data.success) {
          commit('SET_SNACKBAR', { status: true, color: 'success', text: 'Success', icon: 'check', timeout: '2000' })
          // Not created from top create ticket button -> print the ticket
          if (!state.is_pre_selected) {
            let routeData = router.resolve({ path: `/ticket/${response.data.data.ticket_id}` })
            window.open(routeData.href, '_blank')
          }
          commit('SET_CREATE_TICKET_DIALOG_VISIBILITY', false)
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Error occur when the system trying to create ticket, please try again later.', icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Occur Error', icon: 'close', timeout: '2000' })
      }
    }
  }
}
