<template>
  <v-app v-if="$route.path.includes('/ticket')">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
  <v-app v-else>
    <div :class="{ redBg: auth.status }"></div>
    <div :class="{ greyBg: auth.status }"></div>
    <drawer />
    <appbar />
    <v-main style="z-index: 2; position: relative">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
    <snackbar />

    <vue-confirm-dialog />
  </v-app>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import snackbar from '@/components/widgets/snackbar'
import drawer from '@/components/navbars/drawer'
import appbar from '@/components/navbars/appbar'

export default {
  name: 'App',
  components: { snackbar, drawer, appbar },
  data() {
    return {
      registered: false
    }
  },
  computed: { ...mapState(['auth']) },
  methods: {
    ...mapActions(['initTicketingSystem', 'socket_update_queue', 'verifyToken']),
    ...mapMutations(['RESET_AUTH_STATE'])
  },
  async mounted() {
    // If login, check wether the token is still valid
    if (this.auth.status) {
      const response = await this.$api.verifyToken({
        params: { token: this.auth.token }
      })

      // Force Login if the token expired
      if (!response.data.success) {
        this.RESET_AUTH_STATE()
        this.$router.push({ path: '/' })
      } else {
        // Init the system, get service list, window list and user list
        this.initTicketingSystem()
      }
    }

    setInterval(() => {
      this.verifyToken()
    }, 10000)
  },
  created() {
    setInterval(() => {
      if (this.auth.status) {
        if (this.$socket.disconnected) this.registered = false
        // Auto-Connect to queue socket server if user logged in and conduct registration
        if (!this.registered) {
          this.$socket.connect()
          if (this.$socket.connected) {
            this.registered = true
            this.$socket.emit('register', { token: this.auth.token })
            this.socket_update_queue()
          }
        }
      } else {
        // Auto-Disconnect to queue socket server if user didn't login
        if (this.$socket.connected) {
          this.$socket.disconnect()
        }
      }
    }, 1000)
  }
}
</script>

<style scoped>
.redBg {
  position: fixed !important;
  top: 0px !important;
  left: 0px !important;
  width: 100% !important;
  height: 30vh !important;
  z-index: 1 !important;
  background: rgb(206, 45, 45) !important;
  background: linear-gradient(50deg, rgba(206, 45, 45, 1) 0%, rgba(205, 47, 97, 1) 63%) !important;
}

.greyBg {
  position: absolute;
  top: 0px !important;
  left: 0px !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 0 !important;
  background: #f5f5f5;
}
</style>

<style>
* {
  font-family: PingFangHK-Semibold, sans-serif;
}

body {
  touch-action: pan-x pan-y;
}

/* width */
/* ::-webkit-scrollbar {
  width: 5px;
} */

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(187, 187, 187);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
