import axios from './axios.js'
import url from './urls.js'

export default {
  // Auth
  login: data => axios.post(url.login, data.params),
  loginBySSO: data => axios.post(url.login_by_sso, data.params, data.headers),
  logout: data => axios.post(url.logout, data.params),
  verifyToken: data => axios.post(url.verify_token, data.params),

  // Queues
  getQueues: () => axios.get(url.queues),
  getSpecificQueue: queue_id => axios.get(`${url.queues}?queue_id=${queue_id}`),
  modifyTicketPosition: data => axios.put(url.queues, data.params),
  pickTicket: data => axios.post(url.pick_ticket, data.params),

  // Users
  getUsers: () => axios.get(url.users),

  // User's Skillset
  getUserSkillset: user_id => axios.get(`${url.skillsets}?user_id=${user_id}`),

  // Windows
  getWindows: () => axios.get(url.windows),

  // Service Types
  getServiceTypes: () => axios.get(url.service_types),
  getServiceTypeByID: service_type_id => axios.get(`${url.service_types}?service_type_id=${service_type_id}`),

  // Tickets
  getTicketInfo: id => axios.get(`${url.tickets}?ticket_id=${id}`),
  getTicketStatus: id => axios.get(`${url.tickets}/status?ticket_user_id=${id}`),
  createTicket: data => axios.post(url.tickets, data.params),
  updateTicket: data => axios.put(url.tickets, data.params),
  assignTicket: data => axios.post(url.assign_ticket, data.params),
  updateTicketUser: data => axios.put(url.update_ticket_user, data.params),
  updateTicketStatus: data => axios.put(url.update_ticket_status, data.params),

  // Bookings
  getBookings: data => axios.get(`${url.bookings}?tel=${data.params.tel}&from_date=${data.params.from_date}&to_date=${data.params.to_date}`)
}
